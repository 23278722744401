.stageLayout {
	@media (min-width: $fromXLarge) {
		&__legalLinks {
			display: none;
		}
	}

	&__legalLinks {
		justify-content: center;
	}

	&__legalLinksItem {
		color: $tertiary;
		text-transform: none;
		font-weight: normal;
		&:hover {
			color: var(--skin-color-primary-hover, $hover-primary);
		}
	}
}
