@import "/home/runner/work/diakonie-onlineBeratung-frontend/diakonie-onlineBeratung-frontend/src/resources/styles/settings.scss"; @import "/home/runner/work/diakonie-onlineBeratung-frontend/diakonie-onlineBeratung-frontend/src/extensions/resources/styles/settings.scss";  .text {
	text-align: left;
	margin: 0;
	font-family: $font-family-sans-serif;

	&__standard {
		font-size: $font-size-primary;
		line-height: $line-height-primary;
		color: $text-high-emphasis;
	}

	&__infoLargeStandard {
		font-size: $font-size-primary;
		line-height: $line-height-primary;
		color: $text-high-emphasis;
	}

	&__infoLargeAlternative {
		font-size: $font-size-tertiary;
		line-height: 20px;
		color: $text-low-emphasis;

		.text__label {
			text-transform: none;
		}
	}

	&__infoSmall {
		font-size: $font-size-secondary;
		line-height: $line-height-secondary;
		color: $text-low-emphasis;
	}

	&__infoMedium {
		font-size: $font-size-primary;
		line-height: $line-height-primary;
		color: $text-low-emphasis;
		font-weight: $font-weight-regular;
	}

	&__divider {
		font-family: $font-family-divider;
		font-size: $font-size-secondary;
		font-weight: $text-divider-font-weight;
		text-transform: $text-divider-text-transform;
		line-height: $line-height-secondary;
		color: var(--skin-color-secondary-contrast-safe, $text-low-emphasis);
		letter-spacing: $text-divider-letter-spacing;
	}

	&__label {
		font-weight: $font-weight-medium;
		margin-right: 4px;

		&:after {
			content: ':';
			display: inline-block;
		}

		&--notice {
			color: var(--skin-color-primary-contrast-safe, $primary);
		}
	}

	ul {
		margin: $grid-base 0 12px 0;
		padding-left: 20px;

		li:not(:first-of-type) {
			margin-top: $grid-base;
		}
	}
}
